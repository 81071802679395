import React from 'react'
import { useParams } from 'react-router-dom'

type GameDataType = {
  game_board: string[][]
  game_solutions: string[]
  time_remaining: number
  maximum_score: number
  waiting_room_time: number
}

interface WordScrambleDataInterface {
  data: {
    game_board: string[][]
    game_solutions: string[]
    time_remaining: number
    player_name: string
    game_code: string
    maximum_score: number
    waiting_room_time: number
  }
  status: string
}

export function useWordScrambleData() {
  const gameCode = useParams().gameCode
  const playerName = useParams().playerName
  const [gameData, setGameData] = React.useState<GameDataType | null>(null)
  const [status, setStatus] = React.useState('loading')

  React.useEffect(() => {
    if (!gameCode) return

    function getGameData() {
      fetch(`/wordscramble/game/${gameCode}`)
        .then((resp) => resp.json())
        .then((data: GameDataType) => {
          setGameData(data)
          setStatus('success')
        })
        .catch((err) => {
          console.error(err)
          setStatus('error')
        })
    }

    window.addEventListener('focus', getGameData)
    getGameData()

    return () => {
      window.removeEventListener('focus', getGameData)
    }
  }, [])

  return {
    data: { ...gameData, player_name: playerName, game_code: gameCode },
    status: status,
  } as WordScrambleDataInterface
}

export function postFinalScore(
  gameCode: string,
  playerName: string,
  score: number
) {
  fetch(`/wordscramble/game/${gameCode}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      player_name: playerName,
      player_score: score,
    }),
  })
    .then((resp) => {
      if (resp.status !== 200) {
        throw new Error('Failed to post score')
      }
    })
    .catch((err) => {
      throw new Error('Something went wrong... Failed to post score')
    })
}

export function calcWordScore(wordLength: number) {
  let score

  switch (wordLength) {
    case 0:
    case 1:
    case 2:
      score = 0
      break
    case 3:
    case 4:
      score = 1
      break
    case 5:
      score = 2
      break
    case 6:
      score = 3
      break
    case 7:
      score = 4
      break
    default:
      score = 11
  }

  return score
}
