import React from 'react'
import { useWordScrambleData } from '../lib/game'

type TimerProps = {
  message?: string
  gameState: 'waiting' | 'playing' | 'finished'
  onTimeExpire: () => void
}

const Timer: React.FC<TimerProps> = ({ gameState, message, onTimeExpire }) => {
  const [timeRemaining, setTimeRemaining] = React.useState(1)
  const [isTimerRunning, setIsTimerRunning] = React.useState(true)
  const { data: gameData } = useWordScrambleData()
  const intervalRef = React.useRef<any>()

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeRemaining((prev) => prev - 1)
    }, 1000)
    return () => clearInterval(intervalRef.current)
  }, [])

  React.useEffect(() => {
    if (!gameData.waiting_room_time || !gameData.time_remaining) return

    if (timeRemaining < 0 && isTimerRunning) {
      setIsTimerRunning(false)
      clearInterval(intervalRef.current)
      setTimeRemaining(0)
      onTimeExpire()
    }
  }, [timeRemaining])

  React.useEffect(() => {
    if (!gameData.waiting_room_time || !gameData.time_remaining) return

    if (gameState === 'waiting') {
      setTimeRemaining(gameData.waiting_room_time)
    } else if (gameState === 'playing') {
      setTimeRemaining(gameData.time_remaining)
    }
  }, [gameData.waiting_room_time, gameData.time_remaining, gameState])

  if (!gameData.waiting_room_time || !gameData.time_remaining)
    return <>{message || 'Time left:'} ...</>

  return (
    <>
      {message || 'Time left:'} {timeRemaining}
    </>
  )
}

export default Timer
