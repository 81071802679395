import Cell from './Cell'

type RowProps = {
  row: string[]
  cellSize?: string
}

const Row: React.FC<RowProps> = ({ row, cellSize }) => {
  return (
    <>
      <div className="mb-1 flex justify-center">
        {row.map((letter, i) => (
          <Cell key={i} value={letter} cellSize={cellSize} />
        ))}
      </div>
    </>
  )
}

export default Row
