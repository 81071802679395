import React from 'react'
import {
  SubTitle,
  ActionButton,
  Button,
  Spacer,
  Divider,
  Small,
  Select,
  Body,
  Modal,
  BoardPreview,
  useVestaboardSubscriptionConfigInformation,
  Progress,
  Toast,
  NotificationBanner,
} from '@vestaboard/installables'
import { gameBoard } from '../lib/boards'
import { useIsQuietHours } from '../hooks/useQuietHours'

const waitRoomTimeOptions = [
  {
    id: '0',
    name: 'Start immediately',
  },
  {
    id: '60',
    name: '1 minute',
  },
  {
    id: '120',
    name: '2 minutes',
  },
]

const gameLengthOptions = [
  {
    id: '60',
    name: '1 minute',
  },
  {
    id: '120',
    name: '2 minutes',
  },
  {
    id: '180',
    name: '3 minutes',
  },
  {
    id: '240',
    name: '4 minutes',
  },
]

const SettingsPage = () => {
  const [previewOpen, setPreviewOpen] = React.useState(false)
  const [gameLengthValue, setGameLengthValue] = React.useState('180')
  const [waitRoomTime, setwaitRoomTime] = React.useState('60')
  const { data, loading } = useVestaboardSubscriptionConfigInformation()

  const [startGameLoading, setStartGameLoading] = React.useState(false)
  const [showJoinGame, setShowJoinGame] = React.useState(false)
  const [showSuccessToast, setShowSuccessToast] = React.useState(false)
  const [showErrorToast, setShowErrorToast] = React.useState(false)
  const [showQhToast, setShowQhToast] = React.useState(false)

  const [gameCode, setGameCode] = React.useState('')
  const boardId = data?.subscription?.board[0]?.id || ''
  const { isQuietHours, formattedQuietHours } = useIsQuietHours({ boardId })

  React.useEffect(() => {
    if (!data?.subscription.id) return
  }, [data])

  function handleStartGame() {
    if (isQuietHours) {
      setShowQhToast(true)
      return
    }

    setStartGameLoading(true)

    fetch(`/subscriber`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscription_id: data?.subscription.id,
        configtoken:
          data?.subscription?.otherSubscriptions[0]
            ?.subscriptionConfigurationToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })

    fetch(`/wordscramble/announce`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscription_id: data?.subscription?.id,
        subscription_configuration_token:
          data?.subscription?.otherSubscriptions[0]
            ?.subscriptionConfigurationToken,
        game_time: parseInt(gameLengthValue),
        wait_room_time: parseInt(waitRoomTime),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setGameCode(data.game_code)
        setStartGameLoading(false)
        setShowSuccessToast(true)
        setShowJoinGame(true)
      })
      .catch((error) => {
        setStartGameLoading(false)
        setShowErrorToast(true)
        setShowJoinGame(false)
        console.log(error)
      })
  }

  if (loading)
    return (
      <div className="settings">
        <div>
          <Progress />
        </div>
      </div>
    )

  if (!data)
    return (
      <div className="settings">
        <div>
          <SubTitle>Something went wrong...</SubTitle>
          <Body>Your subscription config token is invalid.</Body>
        </div>
      </div>
    )

  return (
    <div className="settings">
      <div>
        <NotificationBanner text={formattedQuietHours} visible={isQuietHours} />
        <SubTitle>Description</SubTitle>
        <Spacer size="large" />
        <Body>
          Use Vestaboard to play a game of word scramble. Find as many words as
          you can before time expires. High scores and hardest words will be
          revealed at the end of each game.
        </Body>
        <Spacer size="large" />
        <div className="flex gap-2">
          <ActionButton
            buttonType="default"
            type="button"
            onClick={() => setPreviewOpen(true)} // implement function to display the game preview
          >
            Preview
          </ActionButton>
        </div>
        <Spacer size="large" />
        <Divider />
        <Spacer size="large" />
        <div>
          <SubTitle>Wait Room Time</SubTitle>
          <Spacer size="small" />
          <Small>
            Choose how much time to allow participants to join before the game
            board is revealed on Vestaboard.
          </Small>
          <Spacer size="large" />
          <Select
            value={waitRoomTime}
            onValueChange={setwaitRoomTime}
            label="Wait Length"
            options={waitRoomTimeOptions}
            displayNone={false}
          />
          <Spacer size="large" />
          <Divider />
          <Spacer size="large" />
        </div>
        <div>
          <SubTitle>Game Length</SubTitle>
          <Spacer size="small" />
          <Small>
            Choose how long you want each game of word scramble to last once the
            game board is revealed.
          </Small>
          <Spacer size="large" />
          <Select
            value={gameLengthValue}
            onValueChange={setGameLengthValue}
            label="Game Length"
            options={gameLengthOptions}
            displayNone={false}
          />
          <Spacer size="large" />
          <Divider />
          <Spacer size="large" />
        </div>
        {!startGameLoading ? (
          <Button buttonType="white" onClick={() => handleStartGame()}>
            Start a New Game
          </Button>
        ) : (
          <div className="flex justify-center">
            <Progress />
          </div>
        )}

        <Spacer size="small" />
        <Small>
          During gameplay, all other messages to your Vestaboard will be paused.
        </Small>
        {showJoinGame && (
          <Button
            buttonType="white"
            onClick={() => {
              window.open(
                `https://vb.plus/?game_code=${gameCode}`,
                '_blank',
                'noopener, noreferrer'
              )
            }}
          >
            Click here to join the game
          </Button>
        )}
        <Spacer size="large" />
      </div>

      <Modal
        visible={previewOpen}
        transparent={true}
        fullScreenMobile={true}
        onClose={() => setPreviewOpen(false)}
      >
        <BoardPreview characters={gameBoard} />
      </Modal>

      <Toast
        message={
          parseInt(waitRoomTime) > 0
            ? `Success! Game will start in ${waitRoomTime} seconds.`
            : 'Success! Game will start immediately.'
        }
        severity="success"
        onClose={() => setShowSuccessToast(false)}
        open={showSuccessToast}
      />
      <Toast
        message={formattedQuietHours}
        severity="error"
        onClose={() => setShowQhToast(false)}
        open={showQhToast}
      />
      <Toast
        message={`Error! Game will not start.`}
        severity="error"
        onClose={() => setShowErrorToast(false)}
        open={showErrorToast}
      />
    </div>
  )
}

export default SettingsPage
