import React from 'react'
import Row from './Row'

type BoardProps = {
  boardState: string[][]
  cellSize?: string
}

const Board: React.FC<BoardProps> = ({ boardState, cellSize }) => {
  return (
    <div className="flex justify-center">
      <ul
        className="border-2 border-solid"
        style={{
          borderColor: '#FFC107',
          fontFamily:
            'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
        }}
      >
        {boardState.map((row, idx) => {
          return (
            <li key={idx}>
              <Row row={row} cellSize={cellSize} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Board
