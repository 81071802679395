import React from 'react'

const FoundWords: React.FC<{ foundWords: string[] }> = ({ foundWords }) => {
  return (
    <div className="mono text-2xl">
      <ul>
        {foundWords.map((word, idx) => (
          <li
            key={word}
            className={`pl-2 ${idx % 2 === 0 ? 'bg-[#25282a]' : 'bg-[#181919]'}`}
          >
            {word}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FoundWords
