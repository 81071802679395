import React from 'react'
import Board from '../components/board/Board'
import Keyboard from '../components/keyboard/Keyboard'
import VestaboardLogo from '../images/vestabord-logo.png'
import {
  Body,
  TabBar,
  SubTitle,
  Progress,
  Button,
} from '@vestaboard/installables'
import GameOverModal from '../components/modals/GameOverModal'
import Timer from '../components/Timer'
import FoundWords from '../components/FoundWords'
import { calcWordScore, useWordScrambleData, postFinalScore } from '../lib/game'
import type { WordScrambleLeaderboardEntryType } from '../lib/types'
import Leaderboard from '../components/leaderboard/Leaderboard'
import Solutions from '../components/leaderboard/Solutions'
import { InputBox } from '../components/InputBox'

const TabsOptions = [
  {
    key: 'board',
    label: 'Board',
  },
  {
    key: 'foundWords',
    label: 'Found Words',
  },
  {
    key: 'instructions',
    label: 'How to Play',
  },
]

const PlayGamePage = () => {
  const { data, status } = useWordScrambleData()

  const boardState = data?.game_board || [
    ['-', '-', '-', '-'],
    ['-', '-', '-', '-'],
    ['-', '-', '-', '-'],
    ['-', '-', '-', '-'],
  ]
  const timeRemainingFromPageLoad = data?.time_remaining
  const waitingRoomTimeRemaining = data?.waiting_room_time
  const solutions = data?.game_solutions
  const minimumWordLength = 3

  const [wordInputValue, setWordInputValue] = React.useState('')
  const [score, setScore] = React.useState(0)
  const [foundWords, setFoundWords] = React.useState<string[]>([])
  const [leaderboardData, setLeaderboardData] = React.useState<
    WordScrambleLeaderboardEntryType[] | null
  >(null)
  const [isGameOver, setIsGameOver] = React.useState(false)
  const [isWaitingRoom, setIsWaitingRoom] = React.useState(
    waitingRoomTimeRemaining > 0
  )

  const inputWrapperRef = React.useRef(null)
  const inputInnerRef = React.useRef<HTMLInputElement | null>(null)

  const [inputStatus, setInputStatus] = React.useState('')
  const [inputStatusMessage, setInputStatusMessage] = React.useState('')

  const [currentTab, setCurrentTab] = React.useState(0) // board, foundWords, instructions

  function onEnter() {
    let isError = false

    // if the word is longer than three chars and is not in the list of found words
    if (wordInputValue.length < minimumWordLength) {
      isError = true
      setInputStatus('error')
      setInputStatusMessage('Word must be at least 3 characters long')
    }

    if (foundWords.includes(wordInputValue.toUpperCase())) {
      isError = true
      setInputStatus('error')
      setInputStatusMessage('Word already found')
      setWordInputValue('')
    }

    if (!solutions.includes(wordInputValue.toUpperCase())) {
      isError = true
      setInputStatus('error')
      setInputStatusMessage('Word not in solutions')
      setWordInputValue('')
    }

    if (!isError) {
      setScore(score + calcWordScore(wordInputValue.length))
      setFoundWords((foundWords) => [
        ...foundWords,
        wordInputValue.toUpperCase(),
      ])
      setInputStatus('success')
      setInputStatusMessage('Found!')
      setWordInputValue('')
    }

    // return a callback to change the input status
    return setTimeout(() => {
      setInputStatus('')
      setInputStatusMessage('')
    }, 2000)
  }

  function onDelete() {
    setWordInputValue(wordInputValue.slice(0, -1))
  }

  function onChar(c: string) {
    setWordInputValue(wordInputValue + c)
  }

  function handleGameOver() {
    postFinalScore(data.game_code, data.player_name, score)
    setIsGameOver(true)
  }

  React.useEffect(() => {
    // Runs once on load, this is a hacky way give a ref a Vestaboard component
    if (inputWrapperRef.current) {
      inputInnerRef.current = (
        inputWrapperRef.current as HTMLInputElement
      ).querySelector('input')
      // inputInnerRef.current is now the first <input> tag inside <Input>
    }
  }, [])

  React.useEffect(() => {
    // strip all spaces from the input since only one word can be entered
    // TODO: should this be the default behavior or should there be a
    // red border around the input box if the input is invalid

    if (isGameOver) {
      inputInnerRef.current?.blur()
      setWordInputValue('')
    }

    setWordInputValue(wordInputValue.replace(/\s/g, '')) // convert all chars to uppercase
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordInputValue])

  React.useEffect(() => {
    if (!isGameOver) return

    setTimeout(() => {
      fetch(`/wordscramble/game/leaderboard/${data.game_code}`)
        .then((resp) => resp.json())
        .then((data) => {
          setLeaderboardData(data?.leaderboard)
        })
        .catch((err) => {
          console.error(err)
        })
      setIsGameOver(false)
    }, 8 * 1000)

    setLeaderboardData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameOver])

  React.useEffect(() => {
    // if waiting room time is set, start the timer
    if (waitingRoomTimeRemaining > 0) {
      setIsWaitingRoom(true)
    }

    // if waiting room time is undefined wait
    if (!waitingRoomTimeRemaining) {
      setIsWaitingRoom(false)
    }
  }, [waitingRoomTimeRemaining])

  if (status === 'error') {
    return <div>Error</div>
  }

  if (!data || !waitingRoomTimeRemaining || status === 'loading') {
    return (
      <div className="setings">
        <Progress />
      </div>
    )
  }

  if (timeRemainingFromPageLoad < 1) {
    // The game has already ended so redirect back to the framework page with an error
    //return <div className="settings">Game Over</div>
    window.location.href =  `https://vb.plus/?event_over=True&first_name=${data.player_name}`
  }

  if (isWaitingRoom) {
    return (
      <div className="h-screen w-screen bg-[#25282a] text-white">
        <div className="flex items-center bg-[#181919]">
          <div className="mx-auto py-4">
            <img
              src={VestaboardLogo}
              className="h-[0.75rem] w-[8.538rem]"
              alt="Vestaboard Logo"
            />
          </div>
        </div>
        <div className="mx-2 sm:mx-4 md:mx-6">
          <div className="mt-4 grid place-items-center">
            <div className="flex flex-col gap-4 text-center">
              <Timer
                message="Game starting in "
                gameState="waiting"
                onTimeExpire={() => setIsWaitingRoom(false)}
              />
              <SubTitle>
                Thanks for joining! The next game will begin shortly.
              </SubTitle>
            </div>
            <ul className="mt-8 flex flex-col gap-5">
              <li>
                • Submit as many words as you can find in the 4×4 grid before
                time expires.
              </li>
              <li>
                • Words count when the letters are touching vertically,
                horizontally, or diagonally from each other.
              </li>
              <li>
                • The same character cannot be used more than once in the same
                word.
              </li>
              <li>• Longer words score more points.</li>
              <li>
                • After time expires, players can compare their word lists and
                final scores.
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  if (leaderboardData) {
    return (
      <div className="flex h-screen flex-col bg-[#181919] text-white">
        <div className="flex items-center">
          <div className="mx-auto py-4">
            <img
              src={VestaboardLogo}
              className="h-[0.75rem] w-[8.538rem]"
              alt="Vestaboard Logo"
            />
          </div>
        </div>
        <div className="mx-auto flex w-full flex-col bg-[#25282a]">
          <div className="py-4">
            <Board boardState={boardState} cellSize="2.5rem" />
          </div>
          <Leaderboard
            leaderboardData={leaderboardData}
            currentPlayerName={data.player_name}
          />
          <div className="my-4 mb-16">
            <Solutions
              solutions={solutions}
              foundWords={foundWords}
              maximumScore={data.maximum_score}
            />
          </div>
        </div>
        <footer
          style={{
            width: '90%',
            position: 'fixed',
            left: '5%',
            bottom: '1%',
          }}
        >
          <a href={`https://vb.plus/?event_over=True&first_name=${data.player_name}`}>
            <Button buttonType="white" onClick={() => {}}>
              Play Again?
            </Button>
          </a>
        </footer>
      </div>
    )
  }

  return (
    <div className="flex h-screen flex-col bg-[#181919] text-white">
      <div className="flex items-center">
        <div className="mx-auto py-4">
          <img
            src={VestaboardLogo}
            className="h-[0.75rem] w-[8.538rem]"
            alt="Vestaboard Logo"
          />
        </div>
      </div>
      <TabBar
        tabs={TabsOptions}
        disableRipple={true}
        value={currentTab}
        setValue={setCurrentTab}
        fullWidth={true}
      />
      <div className="mx-auto mb-14 flex w-full grow flex-col md:mb-5">
        <div className="grow">
          {currentTab === 0 && (
            <div className="bg-[#25282a] py-5">
              <Board boardState={boardState} />
            </div>
          )}{' '}
          {currentTab === 1 && (
            <div className="bg-[#25282a]">
              <FoundWords foundWords={foundWords} />
            </div>
          )}
          {currentTab === 2 && (
            <div className="h-full bg-[#25282a]">
              <div className="mx-2 py-4 sm:mx-4 md:mx-6">
                <div className="mt-4 grid place-items-center">
                  <div className="flex flex-col gap-4 text-center">
                    <SubTitle>Game Objective</SubTitle>
                  </div>
                  <ul className="mt-8 flex flex-col gap-5">
                    <li>
                      • Submit as many words as you can find in the 4×4 grid
                      before time expires.
                    </li>
                    <li>
                      • Words count when the letters are touching vertically,
                      horizontally, or diagonally from each other.
                    </li>
                    <li>
                      • The same character cannot be used more than once in the
                      same word.
                    </li>
                    <li>• Longer words score more points.</li>
                    <li>
                      • After time expires, players can compare their word lists
                      and final scores.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        {currentTab === 0 && (
          <>
            <div className="mx-auto w-[85%] pb-3 sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4">
              <div className="flex justify-between">
                <Body>Score: {score}</Body>
                <Body>
                  {timeRemainingFromPageLoad ? (
                    <Timer
                      message="Time left: "
                      gameState="playing"
                      onTimeExpire={handleGameOver}
                    />
                  ) : (
                    'Time left: 0'
                  )}
                </Body>
              </div>
              <InputBox
                placeholder=""
                value={wordInputValue}
                status={inputStatus}
                statusMessage={inputStatusMessage}
                onClear={() => setWordInputValue('')}
              />
            </div>
            <Keyboard
              onChar={(c) => {
                if (document.activeElement !== inputInnerRef.current) onChar(c)
              }}
              onDelete={() => {
                if (document.activeElement !== inputInnerRef.current) onDelete()
              }}
              onEnter={onEnter}
            />
          </>
        )}
      </div>
      <GameOverModal isOpen={isGameOver} onClose={() => {}} />
    </div>
  )
}

export default PlayGamePage
