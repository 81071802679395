import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

type GameOverModalProps = {
  isOpen: boolean
  onClose: () => void
}

const GameOverModal: React.FC<GameOverModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
      <div className="relative my-6 mx-auto w-auto max-w-3xl">
        <div className="flex flex-col items-center rounded-lg border-0 bg-white px-[6.5rem] py-6 text-black shadow-lg outline-none focus:outline-none">
          <h3 className="text-3xl font-semibold">Game Over!</h3>
          <p className="text-md mb-6 font-medium">Calculating scores...</p>
          <ClipLoader
            size={50}
            loading={true}
            cssOverride={{
              border: '4px solid',
            }}
            speedMultiplier={0.8}
          />
        </div>
      </div>
    </div>
  )
}

export default GameOverModal
