import SuccessIcon from '../images/sucess.svg'
import ErrorIcon from '../images/error.svg'
import XCircleIcon from '../images/x-circle.svg'

export const InputBox: React.FC<{
  placeholder: string
  status: string
  statusMessage: string
  value: string
  onClear: () => void
}> = ({ placeholder, status, statusMessage, value, onClear }) => {
  return (
    <div className="py-1">
      <div
        className={`w-full rounded-md border ${
          status !== 'error' ? 'border-white' : 'border-[#DC3545]'
        }`}
      >
        <div className="relative">
          <input
            className="w-full border-0 bg-transparent p-2"
            placeholder={placeholder}
            type="text"
            value={value}
            disabled
          />
          {value.length > 0 && (
            <button
              className="absolute top-2.5 right-2.5 h-5 w-5 cursor-pointer"
              onClick={onClear}
            >
              <img src={XCircleIcon} alt="x-circle" />
            </button>
          )}
        </div>
      </div>
      {status === 'error' || status === 'success' ? (
        <>
          {status === 'error' && (
            <div className="mt-1 flex items-center gap-1 text-[#DC3545]">
              <img src={ErrorIcon} alt="error" />
              <div className="text-sm text-[#DC3545]">{statusMessage}</div>
            </div>
          )}
          {status === 'success' && (
            <div className="mt-1 flex items-center gap-1 text-[#9DC284]">
              <img src={SuccessIcon} alt="success" />
              <div className="text-sm text-[#9DC284]">{statusMessage}</div>
            </div>
          )}
        </>
      ) : (
        <div className="mt-[1.5rem]" /> // spacer so that the status message doesn't push the input box up
      )}
    </div>
  )
}
