import React from 'react'
import { calcWordScore } from '../../lib/game'

type SolutionProps = {
  solutions?: string[]
  foundWords?: string[]
  maximumScore: number
}

const Solutions: React.FC<SolutionProps> = ({
  solutions,
  foundWords,
  maximumScore,
}) => {
  if (!solutions) {
    return null
  }

  // sort the solutions by length descending and then alphabetically
  solutions = solutions.sort((a, b) => {
    if (a.length !== b.length) {
      return b.length - a.length
    }
    return a.localeCompare(b)
  })

  return (
    <div className="mx-8">
      <div className="mono text-lg">
        Solutions (Max Possible Score = {maximumScore}):
      </div>
      {solutions.map((solution: string, i) => (
        <div
          key={i}
          className={`my-2 rounded bg-transparent py-1 pl-3 text-lg ${
            foundWords?.includes(solution)
              ? 'text-[#9DC284] outline outline-1 outline-[#9DC284]'
              : ''
          }`}
        >
          {`${solution} (${calcWordScore(solution.length)})`}
        </div>
      ))}
    </div>
  )
}

export default Solutions
