import React from 'react'
import { Theme, VestaboardContextProvider } from '@vestaboard/installables'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SettingsPage from './pages/SettingsPage'
import PlayGamePage from './pages/PlayGamePage'

function App() {
  return (
    <Theme>
      <VestaboardContextProvider>
        <Router>
          <Routes>
            <Route path="/settings" element={<SettingsPage />} />
            <Route
              path="/playgame/:gameCode/:playerName"
              element={<PlayGamePage />}
            />
          </Routes>
        </Router>
      </VestaboardContextProvider>
    </Theme>
  )
}

export default App
