import type { IBoard } from '@vestaboard/installables'

export const gameBoard: IBoard = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 65, 65, 65, 65, 65, 65, 65, 65],
  [0, 0, 8, 15, 23, 0, 13, 1, 14, 25, 0, 0, 0, 0, 65, 0, 7, 18, 5, 16, 0, 65],
  [0, 0, 19, 3, 18, 1, 13, 2, 12, 5, 4, 0, 0, 0, 65, 0, 20, 14, 1, 12, 0, 65],
  [0, 0, 23, 15, 18, 4, 19, 0, 3, 1, 14, 0, 0, 0, 65, 0, 5, 19, 9, 20, 0, 65],
  [0, 0, 25, 15, 21, 0, 6, 9, 14, 4, 60, 0, 0, 0, 65, 0, 4, 18, 5, 19, 0, 65],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 65, 65, 65, 65, 65, 65, 65, 65],
]
