import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  onClick: (value: string) => void
}

export const Key = ({ children, width = 40, value, onClick }: Props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  return (
    <button
      style={{ width: `${width}px`, height: '58px' }}
      className="mx-0.5 flex cursor-pointer select-none items-center justify-center rounded bg-[#818384] text-sm font-bold text-white"
      onClick={handleClick}
    >
      {children || value}
    </button>
  )
}
