import React from 'react'
import type { WordScrambleLeaderboardEntryType } from '../../lib/types'
import { convertToOrdinalSuffix } from '../../lib/utils'

type LeaderboardProps = {
  leaderboardData: WordScrambleLeaderboardEntryType[] | null
  currentPlayerName: string
}

const Leaderboard: React.FC<LeaderboardProps> = ({
  leaderboardData,
  currentPlayerName,
}) => {
  if (!leaderboardData) return null
  leaderboardData.sort((a, b) => b.player_score - a.player_score)

  return (
    <>
      {leaderboardData.map((entry, idx) => (
        <div
          className={`mx-8 my-2 rounded bg-[#121212] ${
            entry.player_name === currentPlayerName
              ? 'outline outline-1 outline-[#9DC284]'
              : ''
          }`}
        >
          {idx > 0 ? (
            <div className="flex items-center px-6 py-2">
              <div className="mono text-lg">
                {convertToOrdinalSuffix(idx + 1)}
              </div>
              <div className="flex w-full flex-row justify-between pl-6 text-lg">
                <div>{entry.player_name}</div>
                <div className="font-bold">{entry.player_score} points</div>
              </div>
            </div>
          ) : (
            <div className="flex items-center px-4 py-2">
              <TrophyIcon className="h-16 w-16" />
              <div className="flex w-full flex-row justify-between pl-4 text-lg">
                <div className="flex flex-col">
                  <div className="mono text-lg">Winner!</div>
                  <div className="text-2xl">{entry.player_name}</div>
                </div>
                <div className="mr-2 flex items-center text-2xl font-bold">
                  {entry.player_score} points
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  )
}

const TrophyIcon: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 32.6667C24.15 32.6667 21 29.5167 21 25.6667V11.6667H35V25.6667C35 29.5167 31.85 32.6667 28 32.6667Z"
        fill="#FFE38E"
      />
      <path
        d="M44.3333 11.6667H39.6667V7H16.3333V11.6667H11.6667C9.1 11.6667 7 13.7667 7 16.3333V18.6667C7 24.6167 11.48 29.47 17.2433 30.1933C18.7133 33.6933 21.8633 36.33 25.6667 37.1V44.3333H16.3333V49H39.6667V44.3333H30.3333V37.1C34.1367 36.33 37.2867 33.6933 38.7567 30.1933C44.52 29.47 49 24.6167 49 18.6667V16.3333C49 13.7667 46.9 11.6667 44.3333 11.6667ZM11.6667 18.6667V16.3333H16.3333V25.2467C13.6267 24.2667 11.6667 21.7 11.6667 18.6667ZM28 32.6667C24.15 32.6667 21 29.5167 21 25.6667V11.6667H35V25.6667C35 29.5167 31.85 32.6667 28 32.6667ZM44.3333 18.6667C44.3333 21.7 42.3733 24.2667 39.6667 25.2467V16.3333H44.3333V18.6667Z"
        fill="#FFC107"
      />
    </svg>
  )
}

export default Leaderboard
