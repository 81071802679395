type CellProps = {
  value: string
  cellSize?: string
}

const Cell: React.FC<CellProps> = ({ value, cellSize }) => {
  return (
    <>
      <div
        className={`mx-0.5 flex items-center justify-center rounded text-3xl font-bold ${
          cellSize ? `` : 'h-14 w-14'
        }`}
        style={{
          width: cellSize,
          height: cellSize,
        }}
      >
        {value}
      </div>
    </>
  )
}

export default Cell
