import { Key } from './Key'
import { useEffect } from 'react'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
}

const Keyboard = ({ onChar, onDelete, onEnter }: Props) => {
  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        const key = e.key.toUpperCase()
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div>
      <div className="mb-1 flex justify-center">
        <Key value="Q" onClick={onClick} />
        <Key value="W" onClick={onClick} />
        <Key value="E" onClick={onClick} />
        <Key value="R" onClick={onClick} />
        <Key value="T" onClick={onClick} />
        <Key value="Y" onClick={onClick} />
        <Key value="U" onClick={onClick} />
        <Key value="I" onClick={onClick} />
        <Key value="O" onClick={onClick} />
        <Key value="P" onClick={onClick} />
      </div>
      <div className="mb-1 flex justify-center">
        <Key value="A" onClick={onClick} />
        <Key value="S" onClick={onClick} />
        <Key value="D" onClick={onClick} />
        <Key value="F" onClick={onClick} />
        <Key value="G" onClick={onClick} />
        <Key value="H" onClick={onClick} />
        <Key value="J" onClick={onClick} />
        <Key value="K" onClick={onClick} />
        <Key value="L" onClick={onClick} />
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick}>
          Enter
        </Key>
        <Key value="Z" onClick={onClick} />
        <Key value="X" onClick={onClick} />
        <Key value="C" onClick={onClick} />
        <Key value="V" onClick={onClick} />
        <Key value="B" onClick={onClick} />
        <Key value="N" onClick={onClick} />
        <Key value="M" onClick={onClick} />
        <Key width={65.4} value="DELETE" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              fill="white"
              d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
            ></path>
          </svg>
        </Key>
      </div>
    </div>
  )
}

export default Keyboard
